import React from "react"
import ArrowZ from "../../../common/ArrowZ"

const Opportunities = ({jobList}) => {
  return (
    <section
      className="p-careers__common-opportunities p-careers__farm-opportunities"
      data-updown-target="careers"
    >
      <div className="p-careers__common-opportunities__wrapper">
        <div className="p-contents p-contents__opportunities">
          <div className="p-contents__wrapper">
            <h2 className="c-ttl__en p-contents__en">CAREER OPPORTUNITIES</h2>
            <div className="p-contents__block">
              <h3 className="c-ttl__main p-contents__ttl">
                私たちが求める職種
              </h3>
              <p className="c-txt__main p-contents__txt">
                CRISPは、飲食業の枠を超えて様々なキャリアのパートナーを積極採用しています。それぞれの専門領域を活かし、お客様に新たなレストラン体験を届ける仕組みをつくっていきます。
              </p>
            </div>
          </div>
        </div>
        <section className="p-careers__common-opportunities__div">
          <div className="p-careers__common-opportunities__div-wrapper">
            <h4 className="c-ttl__en p-careers__common-opportunities__div-ttl">
              CORPORATE
            </h4>
            {jobList.length === 0 ? (
              <ul className="p-careers__common-opportunities__div-list">
                <li>
                  <div className="c-link__main p-careers__common-opportunities__div-link__wrapper">
                    <span className="p-careers__common-opportunities__div-link-span">
                      <span className="c-link__main-inner">
                        現在募集中の職種はありません
                      </span>
                    </span>
                  </div>
                </li>
              </ul>
            ) : (
              <ul className="p-careers__common-opportunities__div-list">
                {jobList.map(job => {
                  return (
                    <li
                      className="js-hover p-careers__common-opportunities__div-item"
                      data-hover-in-time="600"
                      data-hover-out-time="650"
                    >
                      <a
                        className="p-careers__common-opportunities__div-link"
                        href={job.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="c-link__main p-careers__common-opportunities__div-link__wrapper">
                          <span className="p-careers__common-opportunities__div-link-span">
                            <span className="c-link__main-inner">
                              {job.name}
                            </span>
                            <ArrowZ/>
                          </span>
                        </div>
                      </a>
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        </section>
      </div>
    </section>
  )
}

export default Opportunities
